import React from "react";
import Seo from "../components/atoms/Seo";
import Container from "../components/atoms/Container";
import styled from "styled-components";
import Image from "../components/atoms/Image";
import { Link } from "gatsby";

const Holder = styled.div`
  position: relative;
  color: ${(props) => props.theme.colours.white};
`;

const Title = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin: 1rem;
  z-index: 2;

  > h1 {
    letter-spacing: -0.1rem;
    margin: 0;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 2rem 4rem;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  &:last-child {
    z-index: -1;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    * {
      max-height: 700px;
    }
  }

  * {
    min-height: 500px;
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.4) 100%);
  z-index: 1;
`;

const Inner = styled.span`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  padding: 2rem 0;
  margin: 0 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0 4rem;
    padding: 4rem 0;
  }
`;

export default function PrivacyPolicy() {

  return (
    <>
      <Seo title="Privacy Policy" />
      <Container>
        <Holder>
          <ImageHolder>
            <Gradient />
            <Image classname="gradientImage" imgName="towel.jpeg" />
          </ImageHolder>
          <Title>
            <h1>Privacy Policy</h1>
          </Title>
        </Holder>
        <Inner>
          <p>Simba Global is committed to providing quality services to you and this policy outlines our ongoing
            obligations to you in respect of how we manage your Personal Information.</p>
          <p>We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the
            Privacy Act). The NPPs govern the way in which we collect, use, disclose, store, secure and dispose of your
            Personal Information. A copy of the Australian Privacy Principles may be obtained from the website of The
            Office of the Australian Information Commissioner at <a href="https://www.oaic.gov.au/" target="_blank"
                                                                    rel="noreferrer noopener">www.aoic.gov.au</a></p>
          <h4>What is Personal Information and why do we collect it?</h4>
          <p>Personal Information is information or an opinion that identifies an individual. Examples of Personal
            Information we collect include: names, addresses, email addresses, phone and facsimile numbers. This
            Personal Information is obtained in many ways including [interviews, correspondence, by telephone and
            facsimile, by email, via our website <Link to="/">simba.global</Link>, from your website, from media and
            publications, from
            other publicly available sources, from cookies- delete all that aren’t applicable] and from third parties.
            We don’t guarantee website links or policy of authorised third parties.</p>
          <p>We collect your Personal Information for the primary purpose of providing our services to you, providing
            information to our clients and marketing. We may also use your Personal Information for secondary purposes
            closely related to the primary purpose, in circumstances where you would reasonably expect such use or
            disclosure.</p>
          <p>You may unsubscribe from our mailing/marketing lists at any time by contacting us in writing. When we
            collect Personal Information we will, where appropriate and where possible, explain to you why we are
            collecting the information and how we plan to use it.</p>
          <h4>Sensitive Information</h4>
          <p>Sensitive information is defined in the Privacy Act to include information or opinion about such things as
            an individual's racial or ethnic origin, political opinions, membership of a political association,
            religious or philosophical beliefs, membership of a trade union or other professional body, criminal record
            or health information. Sensitive information will be used by us only:
            <ul>
              <li>For the primary purpose for which it was obtained</li>
              <li>For a secondary purpose that is directly related to the primary purpose</li>
              <li>With your consent; or where required or authorised by law</li>
            </ul>
          </p>
          <h4>Third Parties</h4>
          <p>Where reasonable and practicable to do so, we will collect your Personal Information only from you.
            However, in some circumstances we may be provided with information by third parties. In such a case we will
            take reasonable steps to ensure that you are made aware of the information provided to us by the third
            party.</p>
          <h4>Disclosure of Personal Information</h4>
          <p>Your Personal Information may be disclosed in a number of circumstances including the following:
            <ul>
              <li>Third parties where you consent to the use or disclosure</li>
              <li>Where required or authorised by law</li>
            </ul>
          </p>
          <h4>Security of Personal Information</h4>
          <p>Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from
            unauthorized access, modification or disclosure. When your Personal Information is no longer needed for the
            purpose for which it was obtained, we will take reasonable steps to destroy or permanently de-identify your
            Personal Information. However, most of the Personal Information is or will be stored in client files which
            will be kept by us for a minimum of 7 years.</p>
          <h4>Access to your Personal Information</h4>
          <p>You may access the Personal Information we hold about you and to update and/or correct it, subject to
            certain exceptions. If you wish to access your Personal Information, please contact us in writing. Simba
            Global will not charge any fee for your access request, but may charge an administrative fee for providing a
            copy of your Personal Information. In order to protect your Personal Information we may require
            identification from you before releasing the requested information.</p>
          <h4>Maintaining the Quality of your Personal Information</h4>
          <p>It is important to us that your Personal Information is up to date. We will take reasonable steps to make
            sure that your Personal Information is accurate, complete and up-to-date. If you find that the information
            we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our
            records and ensure we can continue to provide quality services to you.</p>
          <h4>Cookie Policy</h4>
          <p>This Cookie Policy explains how SIMBA GLOBAL PTY LTD, doing business as Simba Global ("Company,” "we,”
            "us,” and "our") uses cookies and similar technologies to recognize you when you visit our websites at <Link
              to="/">simba.global</Link> ("Websites"). It explains what these technologies are and why we use them,
            as well as your
            rights to control our use of them. In some cases we may use cookies to collect personal information, or that
            becomes personal information if we combine it with other information.</p>
          <h4>What are cookies?</h4>
          <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website.
            Cookies are widely used by website owners in order to make their websites work, or to work more efficiently,
            as well as to provide reporting information. Cookies set by the website owner (in this case, Simba Global)
            are called "first party cookies". Cookies set by parties other than the website owner are called "third
            party cookies". Third party cookies enable third party features or functionality to be provided on or
            through the website (e.g. like advertising, interactive content and analytics). The parties that set these
            third party cookies can recognize your computer both when it visits the website in question and also when it
            visits certain other websites.
          </p>
          <h4>Why do we use cookies?</h4>
          <p>We use first and third party cookies for several reasons. Some cookies are required for technical reasons
            in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies.
            Other cookies also enable us to track and target the interests of our users to enhance the experience on our
            Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other
            purposes.</p>
          <h4>How can I control cookies?</h4>
          <p>
            You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by
            setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select
            which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly
            necessary to provide you with services. The Cookie Consent Manager can be found in the notification banner.
            If you choose to reject cookies, you may
            still use our website though your access to some functionality and areas of our website may be restricted.
            You may also set or amend your web browser controls to accept or refuse cookies. As the means by which you
            can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your
            browser's help menu for more information. In addition, most advertising networks offer you a way to opt out
            of targeted advertising. If you would like
            to find out more information, please visit <a rel="noreferrer noopener" target="_blank"
                                                          href="https://www.aboutads.info/choices/">www.aboutads.info/choices</a> or <a
            rel="noreferrer noopener" target="_blank"
            href="https://www.youronlinechoices.com">www.youronlinechoices.com</a>.
          </p>
          <h4>Policy Updates</h4>
          <p>This Policy may change from time to time and is available on our website.</p>
          <h4>Privacy Policy Complaints and Enquiries</h4>
          <p>If you have any queries or complaints about our Privacy Policy please contact us at:</p>
          <p>
            <a href="https://maps.google.com/?q=289-311 Bayswater Rd, Bayswater North VIC 3153" target="_blank"
               rel="noreferrer noopener">289-311 Bayswater Rd, Bayswater North VIC 3153</a> <br />
            <a href="mailto:inquiries@simba.global" rel="noreferrer noopener">inquiries@simba.global</a><br />
            <a href="tel:(03) 9762 2000" rel="noreferrer noopener">(03) 9762 2000</a>
          </p>
        </Inner>
      </Container>
    </>
  );
}
